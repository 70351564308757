<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-12 mb-3">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo ID:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @keypress.enter="getListJobOrder()"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="object_id_search"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 ">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số nhật ký hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getListJobOrder()" v-model="limit" class="custom-select select-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <datatable stt="true" :columns="columnsTableLogs" :rows="data">
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" slot-scope="props" class="text-center" style="vertical-align: middle">
                <button
                    @click="removeOrder(props.row)"
                    :disabled="props.row.status == 2 || props.row.is_refund == 1 || props.row.is_removed == 1"
                    class="btn btn-danger btn-sm ml-2"
                >
                    <i class="far fa-check-circle"></i> Hủy Order
                </button>
            </td>
        </datatable>
    </div>
</template>
<script>
import { catchError, buildParamQuery } from "@/helpers"
import { removeOrder, getListOrder } from "@/api/services/twitter"
import datatable from "@/components/datatable/datatable"
// import { datalike, datafollow } from "./data-test"

export default {
    name: "twitter-logs",
    components: {
        datatable
    },
    props: {
        panel: String,
        type: String
    },
    data() {
        return {
            object_id_search: "",
            limit: 100,
            data: [],
            columnsTableLogs: [
                { label: "Order Id", field: "id", admin: true },
                { label: "MFB Username", field: "username" },
                {
                    label: "Loại",
                    field: "type",
                    icons: true,
                    link: "profile_pic_url",
                    object_id: "object_id"
                },
                { label: "Twitter", field: "screen_name", twitter_profile_link: true },
                { label: "ID Sedding", field: "object_id", twitter_seeding_link: true, link: "link" },
                { label: "Start", field: "start_like", numeric: true },
                { label: "Số lượng", field: "quantity", numeric: true },
                { label: "Giá", field: "price_per_agency", numeric: true },
                { label: "Tổng tiền", field: "prices_agency", numeric: true },
                { label: "Khởi tạo", field: "created_at", timeago: true },
                { label: "Hết hạn", field: "time_expired", timeago: true },
                { label: "Trạng thái", field: "status_message_html", html: true }
            ]
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        user() {
            return this.$store.state.user.user
        },
        manipulatedData() {
            return this.data.map(order => {
                order.object_data = order.object_data ? JSON.parse(order.object_data) : null
                if (this.type === "follow") {
                    order.profile_pic_url =
                        order.object_data.profile_image_url || order.object_data.profile_image_url_https
                }
                order.status_message_html =
                    order.is_refund || order.is_removed
                        ? `<span class="py-1 badge badge-danger"><i class="material-icons icon-log">info</i>${order.status_message}</span>`
                        : order.count_success >= order.quantity
                        ? `<span class="py-1 badge badge-success"><i class="material-icons icon-log">beenhere</i>${order.status_message}</span>`
                        : `<span class="py-1 badge badge-yellow"><i class="material-icons icon-log">autorenew</i>${order.status_message}</span>`
                order.color = order.is_hidden || order.object_not_exist ? true : false
                return order
            })
        }
    },
    watch: {
        panel(value) {
            if (value == "logs") {
                this.getListJobOrder()
            }
        },
        type() {
            if (this.panel === "logs") {
                this.getListJobOrder()
            }
        }
    },
    methods: {
        getListJobOrder: async function() {
            const { type } = this
            const params = {
                limit: this.limit,
                type,
                object_id: this.object_id_search
            }
            const queries = buildParamQuery(params)
            let response = await getListOrder(queries)
            if (response.success) {
                this.data = response.data || []
                // if (response.data.length > 0) {
                //     this.data = response.data
                // } else if (type == "like") {
                //     this.data = [...datalike]
                // } else {
                //     this.data = [...datafollow]
                // }
            } else {
                this.$toastr.error(catchError(response))
            }
        },
        removeOrder: async function(item) {
            let cancel = false
            await this.$swal({
                title: `HỦY ${item.type.toUpperCase()} ID: ${item.object_id} ?`,
                html: `Sau khi hủy bạn sẽ mất số lượng ${item.type.toUpperCase()} chưa tăng và trừ thêm 5.000 ${
                    this.currency
                } phí để tránh Spam !`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    cancel = true
                }
            })
            if (cancel) {
                let param = {
                    id: item.id
                }
                let data = await removeOrder(param)
                if (data.status === 200 && data.success) {
                    let index = this.data.findIndex(el => el.id === item.id)
                    this.data.splice(index, 1)
                    this.$toastr.success("ID Seeding: " + item.object_id + " - " + data.message)
                } else {
                    this.$toastr.error("ID Seeding: " + item.object_id + " - " + catchError(data))
                }
            }
        }
    }
}
</script>
