<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Link hoặc ID {{ type == "follow" ? "trang cá nhân" : "bài viết" }}:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    :placeholder="objectIdPlaceHolder()"
                                />
                            </div>
                        </div>
                        <div key="" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Số lượng cần tăng:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model.number="quantity" type="number" class="form-control input-light" />
                                <div class="card card-white mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 text-danger font-bold">
                                            Lưu ý: hệ thống hoạt động theo hình thức order tức là bạn order số lượng bao
                                            nhiêu thì hệ thống sẽ tự động lấy trong cơ sở dữ liệu ra số lượng người
                                            tương ứng để like, follow,... cho bạn.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold">
                                            Nên nếu nick của họ bị khóa (checkpoint) sau khi buff sẽ gây ra hiện tưởng
                                            tụt trong lúc buff là bình thường.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold mb-0">
                                            Do đó, vui lòng buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt hoặc
                                            chọn gói bảo hành để được hoàn tiền nếu tụt.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                                <h6 class="text-danger bold font-13">
                                    Giá thấp nhất: {{ price_default }} {{ currency }}
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control input-light"
                                />
                                <div class="card card-white mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 mb-0 text-justify">
                                            Mẹo nhỏ: Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn cần gấp bạn
                                            có thể set giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể
                                            nhé.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="type === 'comment' || type === 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h6 class="text-danger font-bold font-14">
                                            Nghiêm cấm bình luận những nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu
                                            ghẹo, xúc phạm nhân phẩm, danh dự của Cá nhân hoặc Tổ chức.
                                        </h6>
                                        <h6 class="text-danger font-bold font-14">
                                            Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và
                                            phải chịu hoàn toàn trách nhiệm trước pháp luật.
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <label class="mr-2 mt-3"
                                        >Nhập nội dung bình luận (<label class="font-bold"
                                            >Mỗi dòng tương đương với 1 bình luận</label
                                        >):</label
                                    >
                                    <textarea
                                        class="form-control input-light"
                                        rows="10"
                                        v-model="list_messages"
                                        placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 bình luận"
                                    ></textarea>
                                    <span class="badge badge-primary text-white py-1 float-right"
                                        >Số lượng: {{ quantity }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ buff <span class="bold">{{ quantity }} {{ type }}</span> với giá
                                            <span class="green bold">{{ priceSingle | formatNumber }}</span>
                                            {{ currency }} / {{ type }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, tiktok thật để tương tác like, sub,
                                    comment....
                                </p>
                                <p>
                                    - Thời gian làm việc (tăng seeding) và bảo hành tính từ ngày bắt đầu cho đến ngày
                                    kết thúc job, tối đa là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng hệ thống sẽ tự động hoàn lại
                                    số tiền seeding chưa tăng cho bạn
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id và check kỹ job tránh tạo nhầm, tính năng đang trong giai
                                    đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                                <p v-if="type === 'follow'" class="font-bold">- Chỉ nhận link hoặc id trang cá nhân.</p>
                                <p v-else class="font-bold">- Chỉ nhận link hoặc id trang bài viết.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <twitterlogs :panel="panel" :type="type"></twitterlogs>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError } from "@/helpers"
import { createOrder } from "@/api/services/twitter"
import twitterlogs from "./twitter-logs"

export default {
    name: "twitter-order",
    components: { twitterlogs },
    data() {
        return {
            object_id: "",
            type: "",
            is_warranty: false,
            time_start: new Date().toISOString(),
            minDatetime: new Date().toISOString(),
            time_expired: "",
            date_warranty: 7,
            price: 0,
            is_filter: false,
            panel: "order",
            quantity: 100,
            price_default: 100,
            notes: "",
            list_messages: "",
            link: ""
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 95)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.quantity
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        router(value) {
            if (value.includes("follow")) {
                this.type = "follow"
            } else if (value.includes("comment")) {
                this.type = "comment"
            } else this.type = "like"
        },
        list_messages(value) {
            this.quantity = value ? value.match(/.+/g).length : 0
        },
        type(value) {
            this.getPriceSeedingType(value)
        },
        is_warranty(value) {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 88)
            let prices = tool.prices
            let type = this.type
            let getPackage = "tiktok_" + (value ? type + "_warranty" : type)
            let price = prices.find(price => price.package_name === getPackage)
            if (price) {
                this.price = price.price
                this.price_default = price.price
            }
        }
    },
    async created() {
        this.getPriceSeedingType(this.type)
        if (this.$route.path.includes("follow")) {
            this.type = "follow"
        } else if (this.$route.path.includes("comment")) {
            this.type = "comment"
        } else this.type = "like"
        this.time_expired = this.increaseDateByNumber(this.time_start, this.date_warranty)
    },
    methods: {
        order: async function() {
            const { object_id } = this
            if (!object_id) {
                this.$toastr.error(`Vui lòng nhập ${this.getLinkTitle()} !`)
                return
            }
            const validPostLink = object_id.includes("https://twitter.com/") && object_id.includes("/status/")
            if (this.type === "like") {
                if (!validPostLink) {
                    this.$toastr.error(`Vui lòng nhập ${this.getLinkTitle()} đúng định dạng !`)
                    return
                }
            }
            const validId = !!this.getId() && !!this.getLink()
            if (!validId) {
                this.$toastr.error(`Vui lòng nhập ${this.getLinkTitle()} !`)
                return
            }
            if (this.price >= 2 * this.price_default) {
                this.$swal(
                    "Thông báo",
                    "Giá bạn đang chọn cao hơn giá bình thường nhiều lần, vui lòng chỉnh lại giá!",
                    "error"
                )
                return
            }
            if (this.type === "comment" && this.list_messages.length < 1) {
                this.$toastr.error("Vui lòng nhập nội dung cần comment")
                return
            }
            if (this.type === "comment") {
                if (
                    this.list_messages.length > 1 &&
                    this.list_messages
                        .toLowerCase()
                        .match(
                            /dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
                        )
                ) {
                    this.$swal(
                        "Lỗi",
                        "Nội dung bình luận của bạn vi phạm tiêu chuẩn cộng đồng, hãy nhập lại nội dung cần bình luận!",
                        "error"
                    )
                    return
                }
            }
            if (this.price < this.price_default) {
                this.$swal("Lỗi", "Giá cài đặt phải lớn hơn giá thấp nhất", "error")
                return
            }
            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.quantity} ${this.type.toUpperCase()} với giá: ${this.price} ${
                    this.currency
                }/1 ${this.type.toUpperCase()} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.orderConfirm()
                }
            })
        },
        orderConfirm: async function() {
            let postData = {
                link: this.getLink(),
                type: this.type,
                object_id: this.getId(),
                quantity: this.quantity,
                prices: this.price * this.quantity
            }
            if (this.type === "comment") {
                postData.list_messages = this.list_messages.match(/.+/g)
            }
            let data = await createOrder(postData)
            if (data.success) {
                this.$swal(
                    "Thành công",
                    "Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn</br></br>Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy",
                    "success"
                )
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        },
        getPriceSeedingType: function(type) {
            let price = this.prices.find(price => price.package_name.includes(type))
            if (price) {
                this.price_default = price.price
                this.price = price.price
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        increaseDateByNumber(date, number = 0) {
            const dateInit = new Date(date).valueOf()
            const milisecondIncrease = number * 24 * 60 * 60 * 1000
            return new Date(dateInit + milisecondIncrease).toISOString()
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        },
        getPostId: function(url) {
            this.link = url.split("?")[0]
            let result = ""
            if (url.indexOf("tiktok.com") < 0) {
                if (this.type === "follow") {
                    this.link = "https://www.tiktok.com/@" + url
                }
                result = url
            } else {
                let postId = ""
                if (this.type === "follow") {
                    postId = url.match(/(.*)tiktok.com\/@(.*)?/)
                } else postId = url.match(/(.*)\/video\/(.*)?/)
                if (postId) {
                    result = postId[2].split("?")[0]
                }
            }
            if (this.type === "follow") {
                this.link = "https://www.tiktok.com/@" + result
            }
            if (this.link.indexOf("www.tiktok.com") < 0) {
                this.link = this.link.replace("tiktok.com", "www.tiktok.com")
            }
            this.link = this.link.trim()
            if (result) {
                this.$toastr.success("Đã tự động get id cho bạn")
            } else {
                this.$toastr.error("Get ID thất bại, hãy thử Get ID bằng Findids.net")
            }
            return result
        },
        getLinkTitle() {
            switch (this.type) {
                case "like":
                    return "Link bài viết"
                case "follow":
                    return "Link hoặc ID trang cá nhân"
                default:
                    return ""
            }
        },
        getId: function() {
            const { type, object_id } = this
            let result = ""
            if (object_id.includes("twitter.com/")) {
                if (type === "follow") {
                    result = object_id.match(/twitter.com\/(.*)/)[1]
                }
                if (type === "like") {
                    result = object_id.match(/status\/(.*)/)[1]
                }
            } else {
                result = object_id
            }
            return result
        },
        getLink: function() {
            const { type, object_id } = this
            let result = ""
            if (type === "follow") {
                if (object_id.includes("twitter.com/")) {
                    result = object_id
                } else {
                    result = `https://twitter.com/${object_id}`
                }
            } else {
                result = object_id
            }
            return result
        },
        objectIdPlaceHolder() {
            switch (this.type) {
                case "like":
                    return "Ví dụ: https://twitter.com/MyAccountName/status/1234567891234567891"
                case "follow":
                    return "Ví dụ: https://twitter.com/MyAccountName"
                default:
                    return ""
            }
        }
    }
}
</script>
